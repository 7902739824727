import miniToastr from 'mini-toastr'

const notificationService = {
  types: miniToastr.config.types,
  sendNotf: (arr) => {
    switch (arr.type){
        case 'info': {
            miniToastr.setIcon('info', 'i', {'class': 'ri-information-line'});
            miniToastr.info(arr.message, arr.title, 2000, undefined, undefined);
        }
        break;
        case 'success': {
            miniToastr.setIcon('success', 'i', {'class': 'ri-checkbox-circle-line'});
            miniToastr.success(arr.message, arr.title, 2000, undefined, undefined);
        }
        break;
        case 'warn': {
            miniToastr.setIcon('warn', 'i', {'class': 'ri-error-warning-line'});
            miniToastr.warn(arr.message, arr.title, 2000, undefined, undefined);
        }
        break;
        case 'error': {
            miniToastr.setIcon('error', 'i', {'class': 'ri-alert-line'});
            miniToastr.error(arr.message, arr.title, 2000, undefined, undefined);
        }
    }    
  }
}

export default notificationService

/*
EXAMPLE USAGES:

notificationService.sendNotf({"type":"info", "message":"Info message", "title":"INFO"});
notificationService.sendNotf({"type":"success", "message":"Success message", "title":"SUCCESS"});
notificationService.sendNotf({"type":"warn", "message":"Warning message", "title":"WARNING"});
notificationService.sendNotf({"type":"error", "message":"Error message", "title":"ERROR"});
*/