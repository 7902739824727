<template>
    <transition name="modal-fade">
        <div class="modal-backdrop">
            <div id="filter-modal" :class="'modal opacity-class '+getContentClass()" role="dialog" aria-labelledby="modalTitle" aria-describedby="modalDescription">
                <header class="modal-header">
                    <slot name="header">
                        <span class="content-header">
                            <template v-if="content=='filter orders'">
                                {{ lang.filter_modal_title }}
                            </template>
                            <template v-else-if="content == 'filter catalog'">
                                    {{ lang.filter_catalog_modal_title }}
                            </template>
                            <template v-else-if="content == 'filter customers'">
                                {{ lang.filter_customers_modal_title }}
                            </template>
                            <template v-else-if="content == 'search_card'">
                                {{ lang.search_card }}
                            </template>
                        </span>

                        <button type="button" class="btn-close" @click="close" aria-label="Close modal">x</button>
                    </slot>
                </header>
                <section class="modal-body">
                    <slot name="body">

                        <template v-if="content=='filter orders'">

                            <p class="titleFilter">{{ lang.filter_shop_channel }} :</p>
                            <div class="div-contain-filter">

                                <!-- Shop -->
                                <div id="shop-list" class="btn-group ic-dropdown ic-dropdown-light">
                                    <button type="button" class="btn btn-secondary dropdown-toggle btn-dropdown-toggle" @click="openOption('shop-option');" data-toggle="dropdown-menu" aria-haspopup="true" aria-expanded="false">
                                        {{ shop_label }}
                                    </button>
                                    <div id="shop-option" class="dropdown-menu dropdown-list-btn dropdown-max-height">
                                        <div class="dropdown-item not-close-onclick">
                                            <label class="ic-switch ic-switch-small not-close-onclick">
                                                <input type="checkbox" class="sr-only not-close-onclick" name="allShopSelection" @click="setFilterByShop('All shop', lang.all_shop, 0);"  checked>
                                                <span class="ic-switch-slider not-close-onclick rotate-elment"></span>
                                            </label>
                                            <span class="content-filter not-close-onclick">{{ lang.all_shop }}</span>
                                        </div>
                                        <div v-for="(shop_,i) in shop_list" :key="i" class="dropdown-item not-close-onclick">
                                            <label class="ic-switch ic-switch-small">
                                                <input type="checkbox" class="sr-only not-close-onclick clicktrigger" :name="'shopSelection_'+ i" @click="setFilterByShop(shop_.id, shop_.display_name, i);">
                                                <span class="ic-switch-slider not-close-onclick rotate-elment"></span>
                                            </label>
                                            <span class="content-filter not-close-onclick">{{ shop_.display_name }}</span>
                                        </div>
                                        <div class="dropdown-item not-close-onclick"></div>
                                        <div class="dropdown-item close-item center-txt not-close-onclick" @click="openOption('shop-option');">{{ lang.close }}</div>
                                    </div>
                                </div>

                                <!-- Canale -->
                                <div id="channel-list" class="btn-group ic-dropdown ic-dropdown-light">
                                    <button type="button" class="btn btn-secondary dropdown-toggle btn-dropdown-toggle" @click="openOption('channel-option');" data-toggle="dropdown-menu" aria-haspopup="true" aria-expanded="false">
                                        {{ channel_label }}
                                    </button>
                                    <div id="channel-option" class="dropdown-menu dropdown-list-btn dropdown-max-height not-close-onclick">
                                        <div class="dropdown-item not-close-onclick">
                                            <label class="ic-switch ic-switch-small not-close-onclick">
                                                <input type="checkbox" class="sr-only not-close-onclick" name="allChannelSelection" @click="setFilterByChannel('All channel', lang.all_channel, 0);"  checked>
                                                <span class="ic-switch-slider not-close-onclick rotate-elment"></span>
                                            </label>
                                            <span class="content-filter not-close-onclick">{{ lang.all_channel }}</span>
                                        </div>
                                        <div v-for="(channel_,i) in channel_list" :key="i" :class="'dropdown-item channel-item not-close-onclick '+ setShopClass(channel_.all_shops)">
                                            <label class="ic-switch ic-switch-small not-close-onclick">
                                                <input type="checkbox" class="sr-only not-close-onclick" :name="'channelSelection_'+ i" @click="setFilterByChannel(channel_.id, channel_.name, i);">
                                                <span class="ic-switch-slider not-close-onclick rotate-elment"></span>
                                            </label>
                                            <span class="content-filter not-close-onclick">{{ channel_.name }}</span>
                                        </div>
                                        <div class="dropdown-item not-close-onclick"></div>
                                        <div class="dropdown-item close-item center-txt not-close-onclick" @click="openOption('channel-option');">{{ lang.close }}</div>
                                    </div>
                                </div>
                            </div>
                            
                            <br>
                            <p class="titleFilter">{{ lang.filter_order_params }} :</p>
                            <div class="div-contain-filter" id="first_other_params">
                                <!-- State -->                                
                                <div id="state-list" class="btn-group ic-dropdown ic-dropdown-light">
                                    <button type="button" class="btn btn-secondary dropdown-toggle btn-dropdown-toggle" @click="openOption('state-option');" data-toggle="dropdown-menu" aria-haspopup="true" aria-expanded="false">
                                        {{ state_label }}
                                    </button>
                                    <div id="state-option" class="dropdown-menu dropdown-list-btn dropdown-max-height not-close-onclick">
                                        <div class="dropdown-item not-close-onclick">
                                            <label class="ic-switch ic-switch-small not-close-onclick">
                                                <input type="checkbox" class="sr-only not-close-onclick" name="allStateSelection" @click="setFilterByState('All state', lang.all_state, 0);"  checked>
                                                <span class="ic-switch-slider not-close-onclick rotate-elment"></span>
                                            </label>
                                            <span class="content-filter not-close-onclick">{{ lang.all_state }}</span>
                                        </div>
                                        <div v-for="(state_,i) in state_list" :key="i" class="dropdown-item not-close-onclick">
                                            <label class="ic-switch ic-switch-small not-close-onclick">
                                                <input type="checkbox" class="sr-only not-close-onclick" :name="'stateSelection_'+ i" @click="setFilterByState(state_[0], state_[1], i);">
                                                <span class="ic-switch-slider not-close-onclick rotate-elment"></span>
                                            </label>
                                            <span class="content-filter not-close-onclick">{{ state_[1] }}</span>
                                        </div>
                                        <div class="dropdown-item not-close-onclick"></div>
                                        <div class="dropdown-item close-item center-txt not-close-onclick" @click="openOption('state-option');">{{ lang.close }}</div>
                                    </div>
                                </div>
                            
                                <input type="text" class="form-control ic-input filter-input" id="n_ordine" :placeholder="lang.order_ref" autocomplete="off" />
                            </div>  

                            <div class="div-contain-filter single-element">
                                <input type="text" class="form-control ic-input filter-input" id="delivery-city" :placeholder="lang.delivery_city" autocomplete="off" />

                                <!-- Payament sistem -->
                                <div id="payment-list" class="btn-group ic-dropdown ic-dropdown-light">
                                    <button type="button" class="btn btn-secondary dropdown-toggle btn-dropdown-toggle" @click="openOption('payment-option');" data-toggle="dropdown-menu" aria-haspopup="true" aria-expanded="false">
                                        {{ payment_label }}
                                    </button>
                                    <div id="payment-option" class="dropdown-menu dropdown-list-btn dropdown-max-height not-close-onclick">
                                        <div class="dropdown-item not-close-onclick">
                                            <label class="ic-switch ic-switch-small not-close-onclick">
                                                <input type="checkbox" class="sr-only not-close-onclick" name="allPaymentSelection" @click="setFilterByPayament('All payment', lang.all_payment, 0);" checked>
                                                <span class="ic-switch-slider not-close-onclick rotate-elment"></span>
                                            </label>
                                            <span class="content-filter not-close-onclick">{{ lang.all_payment }}</span>
                                        </div>
                                        <div v-for="(paymnt_,i) in payment_list" :key="i" class="dropdown-item not-close-onclick">
                                            <label class="ic-switch ic-switch-small not-close-onclick">
                                                <input type="checkbox" class="sr-only not-close-onclick" :name="'paymentSelection_'+ i" @click="setFilterByPayament(paymnt_[0], paymnt_[1], i);">
                                                <span class="ic-switch-slider not-close-onclick rotate-elment"></span>
                                            </label>
                                            <span class="content-filter not-close-onclick">{{ paymnt_[1] }}</span>
                                        </div>
                                        <div class="dropdown-item not-close-onclick"></div>
                                        <div class="dropdown-item close-item center-txt not-close-onclick" @click="openOption('payment-option');">{{ lang.close }}</div>
                                    </div>
                                </div>
                            </div>

                            <br>
                            <p class="titleFilter">{{ lang.filter_by_date }} :</p>
                            <div class="div-contain-filter">
                                <div class="div-contain-filter contain-date">
                                    <input type="date" class="form-control ic-input filter-input" id="start-date" autocomplete="off" />
                                    {{ lang.start_date }}
                                </div>
                                <div class="div-contain-filter contain-date">
                                    <input type="date" class="form-control ic-input filter-input" id="end-date" autocomplete="off" />
                                    {{ lang.end_date }}
                                </div>
                            </div>

                            <!--<br>
                            <p class="titleFilter">{{ lang.filter_time_slot }} :</p>-->
                            <div class="div-contain-filter">
                                <div class="div-contain-filter contain-date">
                                    <input type="time" class="form-control ic-input filter-input" id="start-hour" autocomplete="off" />
                                    {{ lang.start_hour }}
                                </div>
                                <div class="div-contain-filter contain-date">
                                    <input type="time" class="form-control ic-input filter-input" id="end-hour" autocomplete="off" />
                                    {{ lang.end_hour }}
                                </div>
                            </div>
        
                            <input type="hidden" name="shopFilter" />
                            <input type="hidden" name="stateFilter" />
                            <input type="hidden" name="channelFilter" />
                            <input type="hidden" name="paymentFilter" />
                        </template>

                        <template v-else-if="content == 'filter catalog'">
                        </template>

                        <template v-else-if="content == 'filter customers'">
                            <Loader v-if="isSearchingCard" idVal="loader-cards" classVal="" />

                            <p class="titleFilter">{{ lang.filter_name_surname }} :</p>
                            <div class="div-contain-filter">
                                <input type="text" class="form-control ic-input filter-input" id="cust_name" :placeholder="lang.name" autocomplete="off" />
                                <input type="text" class="form-control ic-input filter-input" id="cust_surname" :placeholder="lang.surname" autocomplete="off" />
                            </div>

                            <div :class="'div-contain-filter '+ checkSingleItems()">
                                <input type="text" class="form-control ic-input filter-input" id="cust_email" placeholder="E-mail" autocomplete="off" />

                                <div class='wrap-checkbox' v-if="promo_env == 'roungick'">
                                    <label class="ic-switch ic-switch-small">
                                        <input type="checkbox" class="sr-only" id="show_new_customer">
                                        <span class="ic-switch-slider not-close-onclick rotate-elment"></span>
                                    </label>
                                    <span class="content-filter not-close-onclick titleFilter">{{ lang.show_new_customer }}</span>
                                </div>
                            </div>

                            <template v-if="canClose == 'false'">
                                <br>
                                <p class="titleFilter">{{ lang.insert_card_number }} :</p>
                                <div class="div-contain-filter single-element">
                                    <input type="text" class="form-control ic-input filter-input start" id="card_to_search" :placeholder="lang.card_numb_simply" autocomplete="off" /> <!-- v-on:keyup.enter="filterResult" -->
                                </div>

                                <button type="button" @click="scanCard('.start')" class="btn ic-btn-small ic-btn-outline-grey btn-add-line btn-scan margin-left"> {{ lang.scan_card }}</button>
                            </template>
                        </template>

                        <template v-else-if="content == 'search_card'">
                            <Loader v-if="isSearchingCard" idVal="loader-cards" classVal="" />

                            <p class="titleFilter">{{ lang.insert_card_number }} :</p>
                            <div class="div-contain-filter">
                                <input type="text" class="form-control ic-input filter-input" id="card_to_search" :placeholder="lang.card_numb_simply" autocomplete="off" /> <!-- v-on:keyup.enter="filterResult" -->
                            </div>

                            <button type="button" @click="scanCard('')" class="btn ic-btn-small ic-btn-outline-grey btn-add-line btn-scan"> {{ lang.scan_card }}</button>
                        </template>
                    </slot>
                </section>
                <footer class="modal-footer">
                    <slot name="footer">
                        <button type="button" id="btn-filter" class="btn ic-btn-small ic-btn-green" @click="filterResult">
                            <template v-if="content == 'search_card'">
                                {{ lang.search }}
                            </template>
                            <template v-else>
                                {{ lang.filter }}
                            </template>
                        </button>
                        <button type="button" class="btn ic-btn-small ic-btn-green" @click="close" aria-label="Close modal">{{ lang.close }}</button>
                    </slot>
                </footer>
            </div>
        </div>
    </transition>
</template>

<script>
    import Loader from './elements/Loader.vue'

    import $ from 'jquery' 
    //import axios from 'axios'
    import * as Utility from '../js/utility.js'
    import { useGettext } from '@jshmrtn/vue3-gettext'

    export default {
        name: 'FilterModal',
        setup() { 
            const language = useGettext();
            return {
               language,
            };
        },
        mounted() {  
            this.lang = Utility.getLanguage(this.language);
            this.shop_label = this.lang.all_shop;
            this.state_label = this.lang.all_state;
            this.channel_label = this.lang.all_channel;
            this.payment_label = this.lang.all_payment;
            this.promo_env = localStorage.getItem('promo_env');

            $(document).click(function(event) {
                if(event.target.classList.toString().includes("btn-dropdown-toggle")) { 
                    event.preventDefault();

                }else if(event.target.classList.toString().includes("not-close-onclick")) { 
                    return;
                }else{
                    $(".dropdown-list-btn").css("display", "none");
                }
            });
        },
        components: {
            //ScanModal,
            Loader
        },
        props: {
            shop_list: [],
            state_list: [],
            channel_list: [],
            payment_list: [],
            canClose: String,
            content: String,
            last_filter: String,
            page_content: String,
            filter_action: String
        },
        data() {
            return {
                lang: "",
                promo_env: "",
                isSearchingCard: false,
                shop_label: "All shops",
                state_label: "All states",
                channel_label: "All channels",
                payment_label: "All payments",
                zindex: 20
            }
        },
        methods: {
            close() {
                this.$emit('close');
            },
            getContentClass() {
                if(this.content == 'filter customers') {
                    return "customers";
                }else if(this.content == 'search_card') {
                    return "search_card";
                }else{
                    return "";
                }
            },
            predisposeLastFilter() { 
                if(this.last_filter == ""){ 
                    if(this.page_content == "day") {
                        $("#start-date").attr("disabled", true);
                        $("#end-date").attr("disabled", true);
                    }else{
                        $("#start-date").attr("disabled", false);
                        $("#end-date").attr("disabled", false);
                    }
                    
                    return;
                }

                if(this.content == 'filter orders'){

                    $("input[name='shopFilter']").val("");
                    $("input[name='stateFilter']").val("");
                    $("input[name='channelFilter']").val("");
                    $("input[name='paymentFilter']").val("");
                    
                    let filter_part = this.last_filter.split("], [");

                    for(let i=0; i<filter_part.length; i++){
                        let content = filter_part[i]; 
 
                        if (content.includes("website_shop_id")) { 
                            for(let c=0; c<this.shop_list.length; c++){

                                let curr_shops = this.shop_list[c]; 
                                if(content.includes(curr_shops.id+", ") || content.includes(", "+curr_shops.id+"]") || content.includes("["+curr_shops.id+"]")){ 
                                    $("input[name='shopSelection_"+c+"']").trigger("click");
                                }
                            }

                        } else if (content.includes("website_channel_id")) {
                            for(let c=0; c<this.channel_list.length; c++){

                                let curr_channel = this.channel_list[c];
                                if(content.includes(curr_channel.id+", ") || content.includes(", "+curr_channel.id+"]") || content.includes("["+curr_channel.id+"]")){
                                    $("input[name='channelSelection_"+c+"']").trigger("click");
                                }
                            }

                        } else if (content.includes("ext_state")) { 
                            for(let c=0; c<this.state_list.length; c++){

                                let curr_state = this.state_list[c]; 
                                if(content.includes("\""+curr_state[0]+"\"")) {
                                    $("input[name='stateSelection_"+c+"']").trigger("click");
                                }
                            }

                        } else if (content.includes("transaction_ids.acquirer_id.id")) { /* DA TESTARE QUANDO SARANNO FORNITI ELENCO CON ID METODI PAGAMENTO */
                            for(let c=0; c<this.payment_list.length; c++){

                                let curr_method = this.payment_list[c];
                                if(content.includes(curr_method[0]+", ") || content.includes(", "+curr_method[0]+"]") || content.includes("["+curr_method[0]+"]")){
                                    $("input[name='paymentSelection_"+c+"']").trigger("click");
                                }
                            }

                        } else if (content.includes("name")) {

                            content = content.replace("[", "").replace("]", "").replace('"name", "ilike", "', "");
                            if(content.endsWith('"')){ 
                                content = content.substring(0, content.length -1);
                            }
                            $("#n_ordine").val(content);

                        } else if (content.includes("partner_shipping_id.city")) {

                            content = content.replace("[", "").replace("]", "").replace('"partner_shipping_id.city", "ilike", "', "");
                            if(content.endsWith('"')){ 
                                content = content.substring(0, content.length -1);
                            }
                            $("#delivery-city").val(content);

                        } else if (content.includes('"date_order", ">="')) {

                            content = content.replace("[", "").replace("]", "").replace('"date_order", ">=", "', "");
                            if(content.endsWith('"')){ 
                                content = content.substring(0, content.length -1);
                            }

                            if(content.includes(" ")){
                                let date_time = content.split(" ");
                                $("#start-date").val(date_time[0]);
                                $("#start-hour").val(date_time[1]);
                            }else{
                                $("#start-date").val(content);
                            }

                        } else if (content.includes('"date_order", "<="')) {

                            content = content.replace("[", "").replace("]", "").replace('"date_order", "<=", "', "");
                            if(content.endsWith('"')){ 
                                content = content.substring(0, content.length -1);
                            }

                            if(content.includes(" ")){
                                let date_time = content.split(" ");
                                $("#end-date").val(date_time[0]);
                                $("#end-hour").val(date_time[1]);
                            }else{
                                $("#end-date").val(content);
                            }
                        }
                    }

                    if(this.page_content == "day") {
                        $("#start-date").attr("disabled", true);
                        $("#end-date").attr("disabled", true);
                    }else{
                        $("#start-date").attr("disabled", false);
                        $("#end-date").attr("disabled", false);
                    }

                }else if(this.content == 'filter catalog'){
                    alert('load last filter')
                }
            },
            openOption (opz) { 
                if($("#"+opz).css("display")=='none'){
                    if(window.outerWidth < 764){
                        $(".dropdown-list-btn").css("display", "none");
                    }

                    this.zindex ++;
                    $("#"+opz).css("display", "block");
                    $("#"+opz).css("z-index", this.zindex);
                }else{
                    $("#"+opz).css("display", "none");
                }
            },
            setShopClass (arr) {
                let out = "";
                for(let i=0; i<arr.length; i++){ 
                    out += "shop_"+ arr[i].shop_id +" ";
                }

                return out;
            },
            formatCommaString (str) {
                let out = "";
                let s = str.split(", ");
                for(let i=0; i<s.length; i++){ 
                    if(s[i].length == 0 || s[i].includes(",")) { continue; }

                    if(out==""){
                        out = s[i];
                    }else{ 
                        out += ", "+s[i];
                    }
                }

                return out;
            },
            setChannelIntoDropDown(value) {    
                if(value == this.lang.all_shop){   
                    for(let i=2; i<($(".channel-item").length+2); i++){ 
                        $(".channel-item:nth-child("+i+")").css("display", "block");
                    }

                }else{
                    for(let i=2; i<($(".channel-item").length+2); i++){ 
                        $(".channel-item:nth-child("+i+")").css("display", "none");
                    }

                    let v = $("input[name='shopFilter']").val().split(", ");
                    for(let i=0; i<v.length; i++){ 
                        $(".shop_"+ v[i]).css("display", "block");
                    }
                }

                this.clearFilterByChannel();
            },
            clearFilterByChannel() {
                for(var i=0; i<this.channel_list.length; i++){
                    $('input[name="channelSelection_'+ i +'"]').prop('checked', false);
                }

                $("input[name='channelFilter']").val(this.lang.all_channel);
                this.channel_label = this.lang.all_channel;

                $("input[name='allChannelSelection']").prop('checked', true);
            },
            setFilterByChannel(id, value, index) {

                if(value==this.lang.all_channel){ 
                    for(var i=0; i<this.channel_list.length; i++){
                        $('input[name="channelSelection_'+ i +'"]').prop('checked', false);
                    }
                }else{
                    $('input[name="allChannelSelection"]').prop('checked', false);
                } 
                
                if(value==this.lang.all_channel){
                    $("input[name='channelFilter']").val(this.lang.all_channel);
                    this.channel_label = value;
                }else{
                    if($('input[name="channelSelection_'+ index +'"]:checked').length>0){
                        
                        let filterList = $("input[name='channelFilter']").val();
                        if(filterList == "" || filterList == this.lang.all_channel){
                            $("input[name='channelFilter']").val(id);
                        }else{
                            $("input[name='channelFilter']").val(filterList + ", "+ id);
                        }

                        if(this.channel_label== "" || this.channel_label == this.lang.all_channel){
                            this.channel_label = value;
                        }else{
                            this.channel_label = this.channel_label + ", "+ value;
                        }
                        
                    }else{
                        let currentVal = "";
                        let filterList = $("input[name='channelFilter']").val();
                        if(filterList.includes(id)){
                            currentVal = filterList.replace(id, "");
                            currentVal = this.formatCommaString(currentVal);

                            $("input[name='channelFilter']").val(currentVal.trim());
                        }

                        if(this.channel_label.includes(value)) {
                            this.channel_label =this.channel_label.replace(value, "");
                            this.channel_label = this.formatCommaString(this.channel_label);
                        }
                    }
                }
                if(this.channel_label == ""){ $("input[name='allChannelSelection']").trigger("click"); }
            },
            setFilterByShop(id, value, index) { 

                if(value==this.lang.all_shop){ 
                    for(let i=0; i<this.shop_list.length; i++){
                        $('input[name="shopSelection_'+ i +'"]').prop('checked', false);
                    }

                }else{ 
                    $('input[name="allShopSelection"]').prop('checked', false);
                } 
                
                if(value==this.lang.all_shop){
                    $("input[name='shopFilter']").val(this.lang.all_shop);
                    this.shop_label = value;
                }else{
                    if($('input[name="shopSelection_'+ index +'"]:checked').length>0){
                        
                        let filterList = $("input[name='shopFilter']").val();
                        if(filterList == "" || filterList == "All shop"){
                            $("input[name='shopFilter']").val(id);
                        }else{
                            $("input[name='shopFilter']").val(filterList + ", "+ id);
                        }

                        if(this.shop_label== "" || this.shop_label == this.lang.all_shop){
                            this.shop_label = value;
                        }else{
                            this.shop_label = this.shop_label + ", "+ value;
                        }
                                               
                    }else{
                        let currentVal = "";
                        let filterList = $("input[name='shopFilter']").val();
                        if(filterList.includes(id)){
                            currentVal = filterList.replace(id, "");
                            currentVal = this.formatCommaString(currentVal);

                            $("input[name='shopFilter']").val(currentVal.trim());
                        }

                        if(this.shop_label.includes(value)) {
                            this.shop_label = this.shop_label.replace(value, "");
                            this.shop_label = this.formatCommaString(this.shop_label);
                        }
                    }
                }

                this.setChannelIntoDropDown(value);
                if(this.shop_label == ""){ $("input[name='allShopSelection']").trigger("click"); }
            },
            setFilterByState (id, value, index) {

                if(value==this.lang.all_state){ 
                    for(var i=0; i<this.state_list.length; i++){
                        $('input[name="stateSelection_'+ i +'"]').prop('checked', false);
                    }
                }else{ 
                    $('input[name="allStateSelection"]').prop('checked', false);
                } 
                
                if(value==this.lang.all_state){
                    $("input[name='stateFilter']").val(this.lang.all_state);
                    this.state_label = value;
                }else{
                    if($('input[name="stateSelection_'+ index +'"]:checked').length>0){
                        
                        let filterList = $("input[name='stateFilter']").val();
                        if(filterList == "" || filterList == this.lang.all_state){
                            $("input[name='stateFilter']").val(id);
                        }else{
                            $("input[name='stateFilter']").val(filterList + ", "+ id);
                        }

                        if(this.state_label== "" || this.state_label == this.lang.all_state){
                            this.state_label = value;
                        }else{
                            this.state_label = this.state_label + ", "+ value;
                        }
                        
                    }else{
                        let currentVal = "";
                        let filterList = $("input[name='stateFilter']").val();
                        if(filterList.includes(id)){
                            currentVal = filterList.replace(id, "");
                            currentVal = this.formatCommaString(currentVal);

                            $("input[name='stateFilter']").val(currentVal.trim());
                        }

                        if(this.state_label.includes(value)) {
                            this.state_label = this.state_label.replace(value, "");
                            this.state_label = this.formatCommaString(this.state_label);
                        }
                    }
                }
                if(this.state_label == ""){ $("input[name='allStateSelection']").trigger("click"); }
            },
            setFilterByPayament (id, value, index) {

                if(value==this.lang.all_payment){ 
                    for(var i=0; i<this.state_list.length; i++){
                        $('input[name="paymentSelection_'+ i +'"]').prop('checked', false);
                    }
                }else{ 
                    $('input[name="allPaymentSelection"]').prop('checked', false);
                } 
                
                if(value==this.lang.all_payment){
                    $("input[name='paymentFilter']").val(this.lang.all_payment);
                    this.payment_label = value;
                }else{
                    if($('input[name="paymentSelection_'+ index +'"]:checked').length>0){
                        
                        let filterList = $("input[name='paymentFilter']").val();
                        if(filterList == "" || filterList == this.lang.all_payment){
                            $("input[name='paymentFilter']").val(id);
                        }else{
                            $("input[name='paymentFilter']").val(filterList + ", "+ id);
                        }

                        if(this.payment_label== "" || this.payment_label == this.lang.all_payment){
                            this.payment_label = value;
                        }else{
                            this.payment_label = this.payment_label + ", "+ value;
                        }
                        
                    }else{
                        let currentVal = "";
                        let filterList = $("input[name='paymentFilter']").val();
                        if(filterList.includes(id)){
                            currentVal = filterList.replace(id, "");
                            currentVal = this.formatCommaString(currentVal);

                            $("input[name='paymentFilter']").val(currentVal.trim());
                        }

                        if(this.payment_label.includes(value)) {
                            this.payment_label = this.payment_label.replace(value, "");
                            this.payment_label = this.formatCommaString(this.payment_label);
                        }
                    }
                }
                if(this.payment_label == ""){ $("input[name='allPaymentSelection']").trigger("click"); }
            },
            filterResult() {
                this.filter_action();
            },
            checkSingleItems() {
                if(this.promo_env == 'roungick') {
                    return '';
                }else{
                    return 'single-element';
                }
            },
            scanCard(ext) {
                this.$parent.isScanModalVisible = true;

                let self = this;
                $("#card_to_search"+ext).focus();
                $("#card_to_search"+ext).change(function() {
                    $(this).blur();
                    self.$parent.isScanModalVisible = false;
                    setTimeout(()=>{
                        $(this).off('change');
                        self.filter_action();
                    },1000);
                });
            }
        },
    }
</script>

<style>
    .modal-fade-enter, .modal-fade-enter-active,
    .modal-fade-leave-active {
        opacity: 0;
    }
    .modal-fade-enter-to, .modal-fade-leave {
        opacity: 1;
    }

    .modal-fade-enter-active,
    .modal-fade-leave-active {
        transition: opacity .5s ease
    }
    .modal-backdrop {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0.3);
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .modal {
        background: #FFFFFF;
        box-shadow: 2px 2px 20px 1px;
        overflow-x: auto;
        display: flex;
        flex-direction: column;
    }
    .modal-header,
    .modal-footer {
        padding: 15px;
        display: flex;
    }
    .modal-header {
        border-bottom: 1px solid #eeeeee;
        color: #4AAE9B;
        justify-content: space-between;
    }
    .modal-footer {
        border-top: 1px solid #eeeeee;
        justify-content: flex-end;
    }
    .modal-body {
        position: relative;
        padding: 20px 10px;
        overflow: auto;
    }
    .btn-close {
        border: none;
        font-size: 20px;
        padding: 20px;
        cursor: pointer;
        font-weight: bold;
        color: #4AAE9B;
        background: transparent;
    }
    .btn-green {
        color: white;
        background: #4AAE9B;
        border: 1px solid #4AAE9B;
        border-radius: 2px;
    }
    #filter-modal {
        width: 800px;
        height: auto; 
        position: unset;
        min-height: 680px;
    }
    #filter-modal.customers {
        min-height: 250px;
    }
    #filter-modal.search_card {
        width: 350px;
        min-height: unset;
    }
    #filter-modal.search_card p.titleFilter {
        margin-left: 20px;
    }
    #shop-list, #channel-list, #state-list, #payment-list {
        min-width: 300px;
        max-width: 300px;
        width: 300px;
    }
    #shop-list button, #channel-list button, #state-list button, #payment-list button {
        min-height: unset;
        font-size: .875rem;
        height: 40px !important;
    }
    #shop-option, #channel-option, #state-option, #payment-option {
        padding-top: 8px;
        cursor: default !important;
        padding-right: 0px !important;
    }
    #payment-option div {
        overflow: hidden;
        text-overflow: ellipsis;
    }
    #shop-list button, #shop-option, #channel-list button, #channel-option, #state-list button, #state-option, #payment-list button, #payment-option {
        /*border: 1px solid #c3cbd3 !important;*/
        cursor: pointer;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        padding-right: 40px;
    }
    .ic-switch-slider {
        border: 1px solid #878787 !important;
    }
    .center-txt {
        text-align: center;
    }
    .content-filter {
        position: relative;
        top: -15px;
        left: 10px;
    }
    .dropdown-item.not-close-onclick {
        padding-top: 10px;
        padding-bottom: 0px;
    }
    .close-item {
        cursor: pointer;
        padding-top: 0px !important;
    }
    .advanced-filter {
        margin-top: 30px;
    }
    .filter-input {
        max-width: 300px;
        min-height: unset;
        height: 40px;
        font-size: 0.775rem;        
    }
    .filter-input.error {
        border-color: #8b0000;
    }
    .div-contain-filter {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        margin-bottom: 20px;
        justify-content: space-around;
    }
    .div-contain-filter.contain-date input {
        margin-right: 20px;
        max-width: 200px;
    }
    .div-contain-filter.contain-date {
        width: 300px;
        color: #6c757d;
        align-items: baseline;
        justify-content: flex-start;
    }
    .titleFilter {
        color: #6c757d;
        margin-left: 50px;
        font-size: .875rem;
    }
    .center-txt {
        text-align: center;
    }
    #payment-list {
        visibility: hidden;
    }
    #filter-modal .div-contain-filter.single-element {
        justify-content: left;
        margin-left: 6%;
    }
    #filter-modal div#loader-cards {
        display: flex;
        padding-top: 30px;
        position: absolute;
        justify-content: center;
        width: calc(100% - 10px);
        height: calc(100% - 20px);
        background-color: rgba(255,255,255,1);
        background-color: rgba(255,255,255,.5);
    }
    .btn-scan {
        margin-bottom: 0px !important;
        margin-left: 15px;
    }
    .btn-scan.margin-left { 
        margin-left: 6%;
    }
    .div-contain-filter .wrap-checkbox {
        position: relative;
        width: 300px;
        top: 10px;
    }
    .content-filter.titleFilter {
       margin-left: 5px;
       top: -5px;
    }

    @media screen and (max-height: 700px) {
        #filter-modal {
            min-height: unset;
            height: 95% !important;
        }
    }

    @media screen and (max-width: 850px) {
        #filter-modal {
            width: 90%;
            height: 95% !important;
            position: unset;
        }
    }

    @media screen and (min-width: 765px) and (max-width: 850px) {
        .titleFilter {
            margin-left: 30px;
        }
        .div-contain-filter {
            padding-left: 30px;
            padding-right: 30px;
            justify-content: space-between;
        }
        .div-contain-filter.contain-date {
            padding-left: 0px;
            padding-right: 0px;
            justify-content: flex-start;
        }
        #filter-modal .div-contain-filter.single-element {
            margin-left: 0px;
        }
        .btn-scan.margin-left {
            margin-left: 30px;
        }
    }

    @media screen and (max-width: 764px) {
        #shop-list, #channel-list, #state-list, #payment-list, .filter-input {
            width: calc(100% - 60px);
            margin-bottom: 15px;
            min-width: unset;
            max-width: unset;
        }
        #payment-list {
            display: none;
        }
        #first_other_params {
            margin-bottom: 0px;
        }
        .titleFilter {
            margin-left: 30px;
        }
        .div-contain-filter.contain-date {
            width: 100%;
            padding-left: 30px;
            padding-right: 30px;
        }
        .btn-scan.margin-left {
            margin-left: 30px;
        }
        #filter-modal .div-contain-filter.single-element{
            justify-content: center;
            margin-left: 0px;
        }
    }

    @media screen and (max-width: 500px) {
        span.content-header {
            font-size: 1.18rem;
        }
        .div-contain-filter.contain-date {
            padding-left: 20px;
            padding-right: 15px;
        }
        .div-contain-filter.contain-date input {
            margin-right: 10px;
            margin-left: 10px;
            max-width: 170px;
        }
    }    

    @media screen and (max-width: 350px) {
        .div-contain-filter.contain-date input {
            max-width: 140px;
        }
    }

    .err-shake {
        /* Start the shake animation and make the animation last for 0.5 seconds */
        animation: shake 0.5s;

        /* When the animation is finished, start again */
        animation-iteration-count: infinite;
    }

    @keyframes shake {
        0% { transform: translate(1px, 1px) rotate(0deg); }
        10% { transform: translate(-1px, -2px) rotate(-1deg); }
        20% { transform: translate(-3px, 0px) rotate(1deg); }
        30% { transform: translate(3px, 2px) rotate(0deg); }
        40% { transform: translate(1px, -1px) rotate(1deg); }
        50% { transform: translate(-1px, 2px) rotate(-1deg); }
        60% { transform: translate(-3px, 1px) rotate(0deg); }
        70% { transform: translate(3px, 1px) rotate(-1deg); }
        80% { transform: translate(-1px, -1px) rotate(1deg); }
        90% { transform: translate(1px, 2px) rotate(0deg); }
        100% { transform: translate(1px, -2px) rotate(-1deg); }
    }
</style>