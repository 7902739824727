<template>
    <nav id="sidebarMenu" class="col-md-3 col-lg-2 d-md-block bg-light sidebar collapse">
        <div class="sidebar-sticky pt-3">
            <ul class="nav flex-column">
                <!--<li class="nav-item">
                    <a :class="dashboard_link_class" :href="siteroot +'card-manager.html'">
                        <i class="ri-home-3-line icon-sidebar"></i>
                        Dashboard 
                    </a>
                </li>-->

                <li class="nav-item">
                    <a :class="add_booking_link_class" :href="siteroot +'add_booking.html'">
                        <i class="ri-folders-line icon-sidebar"></i>
                        {{ lang.reservations }} 
                    </a>
                </li>

                <li class="nav-item">
                    <a :class="reservations_block_link_class" :href="siteroot +'reservations_block.html'">
                        <i class="ri-stack-line icon-sidebar"></i>
                        <span class='big_span'>{{ lang.reservations_block }}</span>
                        <span class='small_span'>{{ lang.blocks }}</span>
                    </a>
                </li>

                <li class="nav-item">
                    <a :class="manage_cluster_link_class" :href="siteroot +'manage-clusters.html'">
                        <i class="ri-organization-chart icon-sidebar"></i>
                        {{ lang.manage_cluster }} 
                    </a>
                </li>
                
                <li class="nav-item">
                    <a :class="card_list_link_class" :href="siteroot +'customer-list.html'">
                        <i class="ri-group-line icon-sidebar"></i>
                        <span class='big_span'>{{ lang.mng_customer }}</span>
                        <span class='small_span'>{{ lang.customers }}</span>
                    </a>
                </li>

                <li class="nav-item">
                    <a :class="manage_package_link_class" :href="siteroot +'manage-services.html'">
                        <i class="ri-tools-line icon-sidebar"></i>
                        {{ lang.services }} 
                    </a>
                </li>

                <li class="nav-item">
                    <a :class="provide_service_link_class" :href="siteroot +'provide-services.html'">
                        <i class="ri-exchange-line icon-sidebar"></i>
                        {{ lang.provide_service }} 
                    </a>
                </li>

                <li class="nav-item">
                    <a :class="manage_transactions_link_class" :href="siteroot +'manage-transactions.html'">
                        <i class="ri-bank-card-line icon-sidebar "></i>
                        {{ lang.manage_transactions }} 
                    </a>
                </li>

                <li class="nav-item">
                    <a :class="report_link_class" :href="siteroot +'report.html'">
                        <i class="ri-folder-chart-line icon-sidebar "></i>
                        Report 
                    </a>
                </li>

                <li class="nav-item logout-li">
                    <a class="nav-link" :href="getUrlLogout()">
                        <i class="ri-logout-box-line icon-sidebar"></i>
                        {{ lang.sign_out }}
                    </a>
                </li>
            </ul>
        </div>
    </nav>
</template>

<script>
    import * as Utility from '../../js/utility.js'
    import { useGettext } from '@jshmrtn/vue3-gettext'

    export default {
        name: 'SidebarMenu',
        setup() { 
            const language = useGettext();
            return {
                language,
            };
        },
        beforeMount() {
            if(window.location.href.includes("report.html")){
                this.report_link_class = "nav-link active";
            }

            if(window.location.href.includes("card-manager.html")){
                this.dashboard_link_class = "nav-link active";
            }
            
            if(window.location.href.includes("customer-list.html")){
                this.card_list_link_class = "nav-link active";
            }

            if(window.location.href.includes("add_booking.html")){
                this.add_booking_link_class = "nav-link active";
            }

            if(window.location.href.includes("manage-services.html")){
                this.manage_package_link_class = "nav-link active";
            }

            if(window.location.href.includes("provide-services.html")){
                this.provide_service_link_class = "nav-link active";
            }

            if(window.location.href.includes("manage-clusters.html")) {
                this.manage_cluster_link_class = "nav-link active";
            }

            if(window.location.href.includes("reservations_block.html")){
                this.reservations_block_link_class = "nav-link active";
            }

            if(window.location.href.includes("manage-transactions.html")){
                this.manage_transactions_link_class = "nav-link active";
            }
        },
        mounted() {
          this.lang = Utility.getLanguage(this.language);
        },
        data() {
            return {
                lang: "",
                report_link_class: "nav-link",
                dashboard_link_class: "nav-link",
                card_list_link_class: "nav-link",
                add_booking_link_class: "nav-link",
                manage_cluster_link_class: "nav-link",
                manage_package_link_class: "nav-link",
                provide_service_link_class: "nav-link",
                reservations_block_link_class: "nav-link",
                manage_transactions_link_class: "nav-link",
                insert_dev_components: process.env.VUE_APP_INSERT_DEV_COMPONENTS,
                siteroot: process.env.VUE_APP_SITEROOT,
            }   
        },
        methods: {
          getUrlLogout(){
            return this.siteroot + localStorage.getItem('url_part');
          }
        }
    }
</script>

<style>
    #sidebarMenu {
        padding: 0px;
        z-index: 200;
        position: fixed;
        padding-left: 5px;
        background-color: #f8f9fa!important;
        border-right: 1px solid rgba(0,0,0,.1)
    }
    .sidebar-sticky {
        position: relative;
        top: 0;
        height: calc(100vh - 48px);
        padding-top: .5rem;
        overflow-x: hidden;
        overflow-y: auto;
    }
    .icon-sidebar {
        font-size: 20px;
        margin-right: 5px;
        position: relative;
        top: 4px;
    }
    .logout-li {
        display: none;
    }
    li.nav-item a {
        color: #959595 !important;
    }
    li.nav-item a:hover {
        color: #343434 !important;
    }
    li.nav-item a.active {
        color: #2EB085 !important;
    }
    li.nav-item a.active:hover {
      color: #248767 !important;
    }
    .small_span {
        display: none;
    }

    @media screen and (max-width: 800px) {
        .big_span {
            display: none;
        }
        .small_span {
            display: inline;
        }
    }
    @media screen and (max-width: 767px) {
        .logout-li {
            display: block;
        }
    }
</style>