<template>
    <transition name="modal-fade">
        <div class="modal-backdrop">
            <div id="datalist_modal" class="modal opacity-class" role="dialog" aria-labelledby="modalTitle" aria-describedby="modalDescription">
                <header class="modal-header">
                    <slot name="header">
                        <span class="content-header">{{ lang.customer_to_add }}</span>

                        <button type="button" class="btn-close" @click="close" aria-label="Close modal">x</button>
                    </slot>
                </header>
                <section class="modal-body">
                    <slot name="body">
                        <div class="wrap-searcher">
                            <input type="hidden" class="form-control ic-input" id="cust_id_">
                            <input type="text" class="form-control ic-input" list="" id="search_cust_" @change="setIcon()" @input="checkForListCust()" :placeholder="lang.search_guest" autocomplete="off">
                            <datalist id="cust_list">
                                <template v-for="(partner,i) in partner_list" :key="i">
                                    <option :data-value="partner.id" :value="partner.name">{{ partner.email }}</option> <!-- @click="setID(partner.id)" -->
                                </template>
                            </datalist>
                            <i id="loader_cust" class="ri-loader-line rotate"></i>
                            <i id="search_cust" class="ri-search-line"></i>
                        </div>

                        <p class="data-error">{{ lang.customer_not_exist }}</p>
                    </slot>
                </section>
                <footer class="modal-footer">
                    <slot name="footer">
                        <button type="button" id="close-alert" class="btn ic-btn-small ic-btn-green" @click="setCustomerToCluster" aria-label="Add customer">{{ lang.save }}</button>
                        <button type="button" id="close-alert" class="btn ic-btn-small ic-btn-green" @click="close" aria-label="Close modal">{{ lang.close }}</button>
                    </slot>
                </footer>
            </div>
        </div>  
    </transition>
</template>

<script>
    import $ from 'jquery'
    import axios from 'axios'
    import * as Utility from '../../js/utility.js'
    import { useGettext } from '@jshmrtn/vue3-gettext'

    export default {
        name: 'DatalistInModal',
        data() {
            return {
                lang: "",
                last_length: 0,
                partner_list: [],
                start_search: false,
                endpoint_partner_url: ""
            }
        },
        setup() { 
            const language = useGettext();
            return {
               language,
            };
        },
        mounted() {  
            this.lang = Utility.getLanguage(this.language);
        },
        methods: {
            close() {
                this.$emit('close');
            },
            setIcon() {
                $("#loader_cust").fadeOut();
                setTimeout(()=>{
                    $("#search_cust").fadeIn();
                }, 300);

                $("#search_cust_").attr("list", "");
            },
            checkForListCust() { 
                if(this.start_search){ return; }

                let input = $("#search_cust_").val();
                let options = $("#cust_list option");

                if(this.last_length > input.length){
                    options.length = 0;
                    this.partner_list = [];
                }
                this.last_length = input.length;

                if(input.length == 0){
                    this.partner_list = [];
                    $("#loader_cust").toggle(false);
                    $("#search_cust").toggle(true);
                }

                if($("#search_cust_").attr("list") == "" && options.length > 0){
                    $("#search_cust_").attr("list", "cust_list");
                }

                if(input.length > 0){                       
                    if(input.length > 2){
                        if(options.length == 0){
                            this.start_search = true;
                            let params = "page_size=9999&filter=[\"name\", \"ilike\", \""+ input +"\"],[\"user_ids\", \"!=\", false], [\"active\",\"=\", true]";
                            axios.get(this.endpoint_partner_url+params).then(response => {                       
                                for(let i=0; i<response.data.data.result.length; i++) {
                                    let partner = response.data.data.result[i];
                                    this.partner_list.push(partner);
                                }   

                                $("#search_cust_").attr("list", "cust_list");

                                return this.start_search = false;
                            }).catch(err => { 
                                this.start_search = false;
                                return this.$parent.manageRequestException(err);
                            });
                        }else{
                            $("#search_cust_").attr("list", "cust_list");
                        }                       
                    }else{
                        $("#search_cust_").attr("list", "");
                    }

                    $("#search_cust").fadeOut();
                    setTimeout(()=>{
                        $("#loader_cust").fadeIn();
                        //this.partner_list = [];
                    }, 300);
                }

                for(var i = 0; i < options.length; i++) {
                    var option = options[i];

                    if(option.value === input) {
                        $("#search_cluster_").blur();
                        setTimeout(()=>{
                            $("#loader_cust").toggle(false);
                            $("#search_cust").toggle(true);
                        }, 500);

                        this.start_search = false;
                        return $("#cust_id_").val(option.getAttribute('data-value'));
                    }
                }

                $("#cust_id_").val('none');
            },
            setID(partner_id) {
                console.log(partner_id);
            },
            setCustomerToCluster() {
                if($("#search_cust_").val() == "") {
                    $("#search_cust_").addClass("ic-form-warning");
                    $("#search_cust_").addClass("err-shake");
                    setTimeout(()=>{
                        $("#search_cust_").removeClass("err-shake");
                    }, 300);
                }

                if($("#cust_id_").val() == 'none') { 
                    return $("p.data-error").fadeIn();
                }else{
                    $("p.data-error").fadeOut();

                    this.$parent.addCustomerToCluster($("#cust_id_").val());
                }
            }
        }
    }
</script>

<style>
    .modal-fade-enter, .modal-fade-enter-active,
    .modal-fade-leave-active {
        opacity: 0;
    }
    .modal-fade-enter-to, .modal-fade-leave {
        opacity: 1;
    }

    .modal-fade-enter-active,
    .modal-fade-leave-active {
        transition: opacity .5s ease
    }
    .modal-backdrop {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0.3);
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .modal {
        background: #FFFFFF;
        box-shadow: 2px 2px 20px 1px;
        overflow-x: auto;
        display: flex;
        flex-direction: column;
    }
    .modal-header,
    .modal-footer {
        padding: 15px;
        display: flex;
    }
    .modal-header {
        border-bottom: 1px solid #eeeeee;
        color: #4AAE9B;
        justify-content: space-between;
    }
    .modal-header .btn-close {
        padding: 0px;
        margin-top: 5px;
        margin-right: 15px;
    }
    .modal-header, .modal-footer {
        padding: 15px;
        display: flex;
    }
    span.content-header {
        line-height: 30px;
        color: #2EB085;
        font-size: 1.4rem;
        cursor: default;
        margin: 5px;
    }
    .modal-footer {
        border-top: 1px solid #eeeeee;
        justify-content: flex-end;
    }
    .modal-body {
        position: relative;
        padding: 20px 10px;
        overflow: auto;
    }
    .btn-close {
        border: none;
        font-size: 20px;
        padding: 20px;
        cursor: pointer;
        font-weight: bold;
        color: #4AAE9B;
        background: transparent;
    }
    button.btn-close:focus {
        outline: none;
    }
    .btn-green {
        color: white;
        background: #4AAE9B;
        border: 1px solid #4AAE9B;
        border-radius: 2px;
    }
    i#loader_cust {
        display: none;
    }

    @media screen and (max-width: 500px) {
        span.content-header {
            font-size: 1.18rem;
        }
    }

    #datalist_modal {
        width: 330px;
        height: 277px; 
        position: unset;        
        transition: width 1s, height 1s;
    }
    #datalist_modal .modal-body {
        padding: 20px;
        display: flex;
        min-height: 140px;
        justify-content: center;
        flex-direction: column-reverse;
    }
    #datalist_modal div.wrap-searcher {
        margin-bottom: 0px;
        margin-top: 10px;
    }
    #datalist_modal div.wrap-searcher i {
        right: 33px;
    }
    #search_cust_::-webkit-calendar-picker-indicator {
        display: none !important;
    }
    p.data-error {
        display: none;
        margin-bottom: 0px;
        padding-left: 3px;
        padding-right: 3px;
        margin-top: 0px !important;
        color: #760000 !important;
    }

    @media screen and (max-width: 400px) {
        #datalist_modal {
            width: 90%;
        }
    }

    #search_cust_.err-shake {
        /* Start the shake animation and make the animation last for 0.5 seconds */
        animation: shake 0.5s;

        /* When the animation is finished, start again */
        animation-iteration-count: infinite;
    }

    @keyframes shake {
        0% { transform: translate(1px, 1px) rotate(0deg); }
        10% { transform: translate(-1px, -2px) rotate(-1deg); }
        20% { transform: translate(-3px, 0px) rotate(1deg); }
        30% { transform: translate(3px, 2px) rotate(0deg); }
        40% { transform: translate(1px, -1px) rotate(1deg); }
        50% { transform: translate(-1px, 2px) rotate(-1deg); }
        60% { transform: translate(-3px, 1px) rotate(0deg); }
        70% { transform: translate(3px, 1px) rotate(-1deg); }
        80% { transform: translate(-1px, -1px) rotate(1deg); }
        90% { transform: translate(1px, 2px) rotate(0deg); }
        100% { transform: translate(1px, -2px) rotate(-1deg); }
    }
</style>