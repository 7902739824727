<template>
    <transition name="modal-fade">
        <div class="modal-backdrop">
            <div id="filter-modal-card" :class="'modal opacity-class '+getContentClass()" role="dialog" aria-labelledby="modalTitle" aria-describedby="modalDescription">
                <header class="modal-header">
                    <slot name="header">
                        <span class="content-header">
                            <span v-if="content == 'filter_bookings'">{{ lang.filter_bookings }}</span>
                            <span v-if="content == 'filter_blocks'">{{ lang.filter_reservation_block }}</span>
                            <span v-if="content == 'filter_rsv_services'">{{ lang.filter_rsv_services }}</span>
                            <span v-if="content == 'filter_customers'">{{ lang.filter_customers }}</span>
                            <span v-if="content == 'filter_daily_services'">{{ lang.filter_daily_services }}</span>
                            <span v-if="content == 'filter_transactions'">{{ lang.filter_transactions }}</span>
                            <span v-if="content == 'filter_report'">{{ lang.filter_report }}</span>
                            <!--{{ lang.filter_card_modal_title }}-->
                        </span>

                        <button type="button" class="btn-close" @click="close" aria-label="Close modal">x</button>
                    </slot>
                </header>

                <section class="modal-body">
                    <slot name="body">
                        <template v-if="content == 'filter_bookings'">
                            <p class="titleFilter">{{ lang.filter_bookings_params }} :</p>
                            <div class="div-contain-filter">
                                <div id="hotel-list" class="btn-group ic-dropdown ic-dropdown-light">
                                    <button type="button" class="btn btn-secondary dropdown-toggle btn-dropdown-toggle" @click="openOption('hotel-option');" data-toggle="dropdown-menu" aria-haspopup="true" aria-expanded="false">
                                        {{ hotel_label }}
                                    </button>

                                    <div id="hotel-option" class="dropdown-menu dropdown-list-btn dropdown-max-height">
                                        <div class="dropdown-item not-close-onclick">
                                            <label class="ic-switch ic-switch-small not-close-onclick">
                                                <input type="checkbox" class="sr-only not-close-onclick" name="allHotelSelection" @click="setFilterByHotel('All hotel', lang.all_hotels, 0);"  checked>
                                                <span class="ic-switch-slider not-close-onclick rotate-elment"></span>
                                            </label>
                                            <span class="content-filter not-close-onclick">{{ lang.all_hotels }}</span>
                                        </div>
                                        <div v-for="(hotel,i) in hotel_list" :key="i" class="dropdown-item not-close-onclick">
                                            <label class="ic-switch ic-switch-small">
                                                <input type="checkbox" class="sr-only not-close-onclick" :name="'hotelSelection_'+ i" @click="setFilterByHotel(hotel.id, hotel.name, i);">
                                                <span class="ic-switch-slider not-close-onclick rotate-elment"></span>
                                            </label>
                                            <span class="content-filter not-close-onclick">{{ hotel.name }}</span>
                                        </div>
                                        <div class="dropdown-item not-close-onclick"></div>
                                        <div class="dropdown-item close-item center-txt not-close-onclick" @click="openOption('hotel-option');">{{ lang.close }}</div>
                                    </div>
                                </div>

                                <input type="text" class="form-control ic-input filter-input" id="partner_name_" :placeholder="lang.customer" autocomplete="off" />
                            </div>
                            <br>

                            <p class="titleFilter">{{ lang.filter_checkin_out }} :</p>
                            <div class="div-contain-filter date">
                                <input type="date" class="form-control ic-input filter-input" id="start-date" autocomplete="off" />
                                <i class="ri-arrow-right-line arrow-dsk"></i>
                                <i class="ri-arrow-down-line arrow-mobile"></i>
                                <input type="date" class="form-control ic-input filter-input" id="end-date" autocomplete="off" />
                            </div>
                            <br>

                            <p class="titleFilter">{{ lang.filter_reservation_ref }} :</p>
                            <div class="div-contain-filter">
                                <input type="text" class="form-control ic-input filter-input" id="reservation_ref_" :placeholder="lang.conf_number" autocomplete="off" />
                                <input type="text" class="form-control ic-input filter-input" id="block_code_" :placeholder="lang.block_code" autocomplete="off" />
                            </div>

                            <input type="hidden" name="hotelFilter" />
                        </template>

                        <template v-if="content == 'filter_blocks'">
                            <p class="titleFilter">{{ lang.filter_bookings_params }} :</p>

                            <div class="div-contain-filter">
                                <div id="hotel-list" class="btn-group ic-dropdown ic-dropdown-light">
                                    <button type="button" class="btn btn-secondary dropdown-toggle btn-dropdown-toggle" @click="openOption('hotel-option');" data-toggle="dropdown-menu" aria-haspopup="true" aria-expanded="false">
                                        {{ hotel_label }}
                                    </button>

                                    <div id="hotel-option" class="dropdown-menu dropdown-list-btn dropdown-max-height">
                                        <div class="dropdown-item not-close-onclick">
                                            <label class="ic-switch ic-switch-small not-close-onclick">
                                                <input type="checkbox" class="sr-only not-close-onclick" name="allHotelSelection" @click="setFilterByHotel('All hotel', lang.all_hotels, 0);"  checked>
                                                <span class="ic-switch-slider not-close-onclick rotate-elment"></span>
                                            </label>
                                            <span class="content-filter not-close-onclick">{{ lang.all_hotels }}</span>
                                        </div>
                                        <div v-for="(hotel,i) in hotel_list" :key="i" class="dropdown-item not-close-onclick">
                                            <label class="ic-switch ic-switch-small">
                                                <input type="checkbox" class="sr-only not-close-onclick" :name="'hotelSelection_'+ i" @click="setFilterByHotel(hotel.id, hotel.name, i);">
                                                <span class="ic-switch-slider not-close-onclick rotate-elment"></span>
                                            </label>
                                            <span class="content-filter not-close-onclick">{{ hotel.name }}</span>
                                        </div>
                                        <div class="dropdown-item not-close-onclick"></div>
                                        <div class="dropdown-item close-item center-txt not-close-onclick" @click="openOption('hotel-option');">{{ lang.close }}</div>
                                    </div>
                                </div>

                                <input type="text" class="form-control ic-input filter-input" id="block-code-filter" :placeholder="lang.block_code" autocomplete="off" />
                            </div>
                            <br>

                            <p class="titleFilter">{{ lang.filter_checkin_out }} :</p>
                            <div class="div-contain-filter date">
                                <input type="date" class="form-control ic-input filter-input" id="start-date-filter" autocomplete="off" />
                                <i class="ri-arrow-right-line arrow-dsk"></i>
                                <i class="ri-arrow-down-line arrow-mobile"></i>
                                <input type="date" class="form-control ic-input filter-input" id="end-date-filter" autocomplete="off" />
                            </div>
                            <br>

                            <input type="hidden" name="hotelFilter" />
                        </template>

                        <template v-if="content == 'filter_rsv_services'">
                            <p class="titleFilter">{{ lang.filter_rsv_srv_params }} :</p>
                            <div class="div-contain-filter">
                                <div id="area-list" class="btn-group ic-dropdown ic-dropdown-light">
                                    <button type="button" class="btn btn-secondary dropdown-toggle btn-dropdown-toggle" @click="openOption('area-option');" data-toggle="dropdown-menu" aria-haspopup="true" aria-expanded="false">
                                        {{ area_label }}
                                    </button>

                                    <div id="area-option" class="dropdown-menu dropdown-list-btn dropdown-max-height">
                                        <div class="dropdown-item not-close-onclick">
                                            <label class="ic-switch ic-switch-small not-close-onclick">
                                                <input type="checkbox" class="sr-only not-close-onclick" name="allAreaSelection" @click="setFilterByArea('All areas', lang.all_areas, 0);"  checked>
                                                <span class="ic-switch-slider not-close-onclick rotate-elment"></span>
                                            </label>
                                            <span class="content-filter not-close-onclick">{{ lang.all_areas }}</span>
                                        </div>
                                        <div v-for="(area,i) in area_list" :key="i" class="dropdown-item not-close-onclick">
                                            <label class="ic-switch ic-switch-small">
                                                <input type="checkbox" class="sr-only not-close-onclick" :name="'areaSelection_'+ i" @click="setFilterByArea(area.value, area.label, i);">
                                                <span class="ic-switch-slider not-close-onclick rotate-elment"></span>
                                            </label>
                                            <span class="content-filter not-close-onclick">{{ area.label }}</span>
                                        </div>
                                        <div class="dropdown-item not-close-onclick"></div>
                                        <div class="dropdown-item close-item center-txt not-close-onclick" @click="openOption('area-option');">{{ lang.close }}</div>
                                    </div>
                                </div>

                                <div id="srv-list" class="btn-group ic-dropdown ic-dropdown-light">
                                    <button type="button" class="btn btn-secondary dropdown-toggle btn-dropdown-toggle" @click="openOption('srv-option');" data-toggle="dropdown-menu" aria-haspopup="true" aria-expanded="false">
                                        {{ srv_label }}
                                    </button>

                                    <div id="srv-option" class="dropdown-menu dropdown-list-btn dropdown-max-height">
                                        <div class="dropdown-item not-close-onclick">
                                            <label class="ic-switch ic-switch-small not-close-onclick">
                                                <input type="checkbox" class="sr-only not-close-onclick" name="allSrvSelection" @click="setFilterBySrv('All services', lang.all_srv, 0);"  checked>
                                                <span class="ic-switch-slider not-close-onclick rotate-elment"></span>
                                            </label>
                                            <span class="content-filter not-close-onclick">{{ lang.all_srv }}</span>
                                        </div>
                                        <div v-for="(srv,i) in list_services" :key="i" class="dropdown-item not-close-onclick">
                                            <label class="ic-switch ic-switch-small">
                                                <input type="checkbox" class="sr-only not-close-onclick" :name="'srvSelection_'+ i" @click="setFilterBySrv(srv.value, srv.label, i);">
                                                <span class="ic-switch-slider not-close-onclick rotate-elment"></span>
                                            </label>
                                            <span class="content-filter not-close-onclick">{{ srv.label }}</span>
                                        </div>
                                        <div class="dropdown-item not-close-onclick"></div>
                                        <div class="dropdown-item close-item center-txt not-close-onclick" @click="openOption('srv-option');">{{ lang.close }}</div>
                                    </div>
                                </div>
                            </div>

                            <div class="div-contain-filter">
                                <div id="guest-list" class="btn-group ic-dropdown ic-dropdown-light">
                                    <button type="button" class="btn btn-secondary dropdown-toggle btn-dropdown-toggle" @click="openOption('guest-option');" data-toggle="dropdown-menu" aria-haspopup="true" aria-expanded="false">
                                        {{ guest_label }}
                                    </button>

                                    <div id="guest-option" class="dropdown-menu dropdown-list-btn dropdown-max-height">
                                        <div class="dropdown-item not-close-onclick">
                                            <label class="ic-switch ic-switch-small not-close-onclick">
                                                <input type="checkbox" class="sr-only not-close-onclick" name="allGuestSelection" @click="setFilterByGuest('All guests', lang.all_guests, 0);"  checked>
                                                <span class="ic-switch-slider not-close-onclick rotate-elment"></span>
                                            </label>
                                            <span class="content-filter not-close-onclick">{{ lang.all_guests }}</span>
                                        </div>
                                        <div v-for="(guest,i) in list_guests" :key="i" class="dropdown-item not-close-onclick">
                                            <label class="ic-switch ic-switch-small">
                                                <input type="checkbox" class="sr-only not-close-onclick" :name="'guestSelection_'+ i" @click="setFilterByGuest(guest.value, guest.label, i);">
                                                <span class="ic-switch-slider not-close-onclick rotate-elment"></span>
                                            </label>
                                            <span class="content-filter not-close-onclick">{{ guest.label }}</span>
                                        </div>
                                        <div class="dropdown-item not-close-onclick"></div>
                                        <div class="dropdown-item close-item center-txt not-close-onclick" @click="openOption('guest-option');">{{ lang.close }}</div>
                                    </div>
                                </div>

                                <input type="text" class="form-control ic-input filter-input fake-field" autocomplete="off" />
                            </div>
                            <br>

                            <p class="titleFilter">{{ lang.filter_by_date }} :</p>
                            <div class="div-contain-filter date">
                                <input type="date" class="form-control ic-input filter-input" id="start-date-rsv" autocomplete="off" />
                                <i class="ri-arrow-right-line arrow-dsk"></i>
                                <i class="ri-arrow-down-line arrow-mobile"></i>
                                <input type="date" class="form-control ic-input filter-input" id="end-date-rsv" autocomplete="off" />
                            </div>
                            <br>

                            <div class="flag-div">
                                <label class="ic-switch ic-switch-small not-close-onclick">
                                    <input type="checkbox" class="sr-only not-close-onclick" checked name="consumedServices">
                                    <span class="ic-switch-slider not-close-onclick rotate-elment"></span>
                                </label>
                                <span class="content-filter not-close-onclick">{{ lang.show_consumed_srv }}</span>
                            </div>

                            <input type="hidden" name="srvFilter" />
                            <input type="hidden" name="areaFilter" />
                            <input type="hidden" name="guestFilter" />
                        </template>

                        <template v-if="content == 'filter_customers'">
                            <p class="titleFilter">{{ lang.filter_cust_data }} :</p>
                            <div class="div-contain-filter">
                                <input type="text" class="form-control ic-input filter-input" id="partner_name_" :placeholder="lang.customer" autocomplete="off" />
                                <input type="text" class="form-control ic-input filter-input" id="partner_mail_" placeholder="Email" autocomplete="off" />
                            </div>
                        </template>

                        <template v-if="content == 'filter_daily_services'">
                            <p class="titleFilter">{{ lang.filter_srv_cust }} :</p>
                            <div class="div-contain-filter">
                                <div id="srv-list" class="btn-group ic-dropdown ic-dropdown-light">
                                    <button type="button" class="btn btn-secondary dropdown-toggle btn-dropdown-toggle" @click="openOption('srv-option');" data-toggle="dropdown-menu" aria-haspopup="true" aria-expanded="false">
                                        {{ srv_label }}
                                    </button>

                                    <div id="srv-option" class="dropdown-menu dropdown-list-btn dropdown-max-height">
                                        <div class="dropdown-item not-close-onclick">
                                            <label class="ic-switch ic-switch-small not-close-onclick">
                                                <input type="checkbox" class="sr-only not-close-onclick" name="allSrvSelection" @click="setFilterBySrv('All services', lang.all_srv, 0);"  checked>
                                                <span class="ic-switch-slider not-close-onclick rotate-elment"></span>
                                            </label>
                                            <span class="content-filter not-close-onclick">{{ lang.all_srv }}</span>
                                        </div>
                                        <div v-for="(srv,i) in list_services" :key="i" class="dropdown-item not-close-onclick">
                                            <label class="ic-switch ic-switch-small">
                                                <input type="checkbox" class="sr-only not-close-onclick" :name="'srvSelection_'+ i" @click="setFilterBySrv(srv.value, srv.label, i);">
                                                <span class="ic-switch-slider not-close-onclick rotate-elment"></span>
                                            </label>
                                            <span class="content-filter not-close-onclick">{{ srv.label }}</span>
                                        </div>
                                        <div class="dropdown-item not-close-onclick"></div>
                                        <div class="dropdown-item close-item center-txt not-close-onclick" @click="openOption('srv-option');">{{ lang.close }}</div>
                                    </div>
                                </div>

                                <input type="text" class="form-control ic-input filter-input" id="partner_name_" :placeholder="lang.customer" autocomplete="off" />
                            </div>

                            <input type="hidden" name="srvFilter" />
                        </template>

                        <template v-if="content == 'filter_transactions'">
                            <p class="titleFilter">{{ lang.filter_trnsct_data }} :</p>
                            <div class="div-contain-filter">
                                <input type="text" class="form-control ic-input filter-input" id="partner_name_" :placeholder="lang.customer" autocomplete="off" />
                                
                                <div id="wallet-list" class="btn-group ic-dropdown ic-dropdown-light">
                                    <button type="button" class="btn btn-secondary dropdown-toggle btn-dropdown-toggle" @click="openOption('wallet-option');" data-toggle="dropdown-menu" aria-haspopup="true" aria-expanded="false">
                                        {{ wallet_label }}
                                    </button>

                                    <div id="wallet-option" class="dropdown-menu dropdown-list-btn dropdown-max-height">
                                        <div class="dropdown-item not-close-onclick">
                                            <label class="ic-switch ic-switch-small not-close-onclick">
                                                <input type="checkbox" class="sr-only not-close-onclick" name="allWalletSelection" @click="setFilterByWallet('All wallets', lang.all_wallets, 0);"  checked>
                                                <span class="ic-switch-slider not-close-onclick rotate-elment"></span>
                                            </label>
                                            <span class="content-filter not-close-onclick">{{ lang.all_wallets }}</span>
                                        </div>
                                        <div v-for="(wallet,i) in wallet_list" :key="i" class="dropdown-item not-close-onclick">
                                            <label class="ic-switch ic-switch-small">
                                                <input type="checkbox" class="sr-only not-close-onclick" :name="'walletSelection_'+ i" @click="setFilterByWallet(wallet.value, wallet.label, i);">
                                                <span class="ic-switch-slider not-close-onclick rotate-elment"></span>
                                            </label>
                                            <span class="content-filter not-close-onclick">{{ wallet.label }}</span>
                                        </div>
                                        <div class="dropdown-item not-close-onclick"></div>
                                        <div class="dropdown-item close-item center-txt not-close-onclick" @click="openOption('wallet-option');">{{ lang.close }}</div>
                                    </div>
                                </div>
                            </div>
                            <br>

                            <div class="div-contain-filter">
                                <div id="operation-list" class="btn-group ic-dropdown ic-dropdown-light">
                                    <button type="button" class="btn btn-secondary dropdown-toggle btn-dropdown-toggle" @click="openOption('operation-option');" data-toggle="dropdown-menu" aria-haspopup="true" aria-expanded="false">
                                        {{ operation_label }}
                                    </button>

                                    <div id="operation-option" class="dropdown-menu dropdown-list-btn dropdown-max-height">
                                        <div class="dropdown-item not-close-onclick">
                                            <label class="ic-switch ic-switch-small not-close-onclick">
                                                <input type="checkbox" class="sr-only not-close-onclick" name="allOperationSelection" @click="setFilterByOperation('All operations', lang.all_operations, 0);"  checked>
                                                <span class="ic-switch-slider not-close-onclick rotate-elment"></span>
                                            </label>
                                            <span class="content-filter not-close-onclick">{{ lang.all_operations }}</span>
                                        </div>
                                        <div v-for="(operation,i) in operations_list" :key="i" class="dropdown-item not-close-onclick">
                                            <label class="ic-switch ic-switch-small">
                                                <input type="checkbox" class="sr-only not-close-onclick" :name="'operationSelection_'+ i" @click="setFilterByOperation(operation.value, operation.label, i);">
                                                <span class="ic-switch-slider not-close-onclick rotate-elment"></span>
                                            </label>
                                            <span class="content-filter not-close-onclick">{{ operation.label }}</span>
                                        </div>
                                        <div class="dropdown-item not-close-onclick"></div>
                                        <div class="dropdown-item close-item center-txt not-close-onclick" @click="openOption('operation-option');">{{ lang.close }}</div>
                                    </div>
                                </div>

                                <input type="text" class="form-control ic-input filter-input" id="rvc_" placeholder="Rvc" autocomplete="off" />
                            </div>
                            <br>

                            <p class="titleFilter">{{ lang.filter_by_date }} :</p>
                            <div class="div-contain-filter date transactions">
                                <input type="date" class="form-control ic-input filter-input" id="start-date" autocomplete="off" />
                                <i class="ri-arrow-right-line arrow-dsk"></i>
                                <i class="ri-arrow-down-line arrow-mobile"></i>
                                <input type="date" class="form-control ic-input filter-input" id="end-date" autocomplete="off" />
                            </div>

                            <input type="hidden" name="walletFilter" />
                            <input type="hidden" name="operationFilter" />
                        </template>

                        <template v-if="content == 'filter_report'">
                            <p class="titleFilter">{{ lang.choose_hotel }} :</p>
                            <div class="div-contain-filter">
                                <div id="hotel-list" class="btn-group ic-dropdown ic-dropdown-light">
                                    <button type="button" class="btn btn-secondary dropdown-toggle btn-dropdown-toggle" @click="openOption('hotel-option');" data-toggle="dropdown-menu" aria-haspopup="true" aria-expanded="false">
                                        {{ hotel_label }}
                                    </button>

                                    <div id="hotel-option" class="dropdown-menu dropdown-list-btn dropdown-max-height">
                                        <div class="dropdown-item not-close-onclick">
                                            <label class="ic-switch ic-switch-small not-close-onclick">
                                                <input type="checkbox" class="sr-only not-close-onclick" name="allHotelSelection" @click="setFilterByHotel('All hotel', lang.all_hotels, 0);"  checked>
                                                <span class="ic-switch-slider not-close-onclick rotate-elment"></span>
                                            </label>
                                            <span class="content-filter not-close-onclick">{{ lang.all_hotels }}</span>
                                        </div>
                                        <div v-for="(hotel,i) in hotel_list" :key="i" class="dropdown-item not-close-onclick">
                                            <label class="ic-switch ic-switch-small">
                                                <input type="checkbox" class="sr-only not-close-onclick" :name="'hotelSelection_'+ i" @click="setFilterByHotel(hotel.id, hotel.name, i);">
                                                <span class="ic-switch-slider not-close-onclick rotate-elment"></span>
                                            </label>
                                            <span class="content-filter not-close-onclick">{{ hotel.name }}</span>
                                        </div>
                                        <div class="dropdown-item not-close-onclick"></div>
                                        <div class="dropdown-item close-item center-txt not-close-onclick" @click="openOption('hotel-option');">{{ lang.close }}</div>
                                    </div>
                                </div>

                                <input type="text" class="fake-field" autocomplete="off" />
                            </div>
                            <br>

                            <p class="titleFilter">{{ lang.filter_by_date }} :</p>
                            <div class="div-contain-filter date">
                                <input type="date" class="form-control ic-input filter-input" id="start-date" autocomplete="off" />
                                <i class="ri-arrow-right-line arrow-dsk"></i>
                                <i class="ri-arrow-down-line arrow-mobile"></i>
                                <input type="date" class="form-control ic-input filter-input" id="end-date" autocomplete="off" />
                            </div>
                            <br>

                            <input type="hidden" name="hotelFilter" />
                        </template>
                    </slot>
                </section>

                <footer class="modal-footer">
                    <slot name="footer">
                        <button type="button" id="btn-filter" class="btn ic-btn-small ic-btn-green" @click="filterResult">{{ lang.filter }}</button>
                        <button type="button" class="btn ic-btn-small ic-btn-green" @click="close" aria-label="Close modal">{{ lang.close }}</button>
                    </slot>
                </footer>
            </div>
        </div>
    </transition>
</template>

<script>
    import $ from 'jquery'
    import * as Utility from '../../js/utility.js'
    import { useGettext } from '@jshmrtn/vue3-gettext'

    export default {
        name: 'FilterModal',
        props: {
            area_list: [],
            hotel_list: [],
            wallet_list: [],
            list_guests: [],
            content: String,
            list_services: [],
            operations_list: [],
            last_filter: String,
            filter_action: String
        },
        data() {
            return {
                lang: "",
                srv_label: "",
                area_label: "",
                guest_label: "",
                hotel_label: "",
                wallet_label: "",
                operation_label: "",
                isLoading: false
            }
        },
        setup() { 
            const language = useGettext();
            return {
               language,
            };
        },
        mounted() {  
            this.lang = Utility.getLanguage(this.language);
            this.operation_label = this.lang.all_operations;
            this.wallet_label = this.lang.all_wallets;
            this.hotel_label = this.lang.all_hotels;
            this.guest_label = this.lang.all_guests;
            this.area_label = this.lang.all_areas;
            this.srv_label = this.lang.all_srv;


            $(document).click(function(event) {
                if(event.target.classList.toString().includes("btn-dropdown-toggle")) { 
                    event.preventDefault();

                }else if(event.target.classList.toString().includes("not-close-onclick")) { 
                    return;
                }else{
                    $(".dropdown-list-btn").css("display", "none");
                }
            });
        },
        methods: {
            close() {
                this.$emit('close');
            },
            filterResult() {
                this.filter_action();
            },
            getContentClass() {
                if(this.content.includes('bookings')) {
                    return 'booking';
                }

                if(this.content == 'filter_blocks') {
                    return 'blocks';
                }

                if(this.content == 'filter_rsv_services') {
                    return 'rsv_services';
                }

                if(this.content == 'filter_customers') {
                    return 'customers';
                }

                if(this.content == 'filter_daily_services') {
                    return 'daily_services';
                }

                if(this.content == 'filter_transactions') {
                    return 'transactions';
                }

                if(this.content == 'filter_report') {
                    return 'report';
                }
            },
            openOption (opz) { 
                if($("#"+opz).css("display")=='none'){
                    if(window.outerWidth < 764){
                        $(".dropdown-list-btn").css("display", "none");
                    }

                    this.zindex ++;
                    $("#"+opz).css("display", "block");
                    $("#"+opz).css("z-index", this.zindex);
                }else{
                    $("#"+opz).css("display", "none");
                }
            },
            setFilterByHotel(id, value, index) { 
                if(value==this.lang.all_hotels){ 
                    for(let i=0; i<this.hotel_list.length; i++){
                        $('input[name="hotelSelection_'+ i +'"]').prop('checked', false);
                    }

                }else{ 
                    $('input[name="allHotelSelection"]').prop('checked', false);
                } 
                
                if(value==this.lang.all_hotels){
                    $("input[name='hotelFilter']").val(this.lang.all_hotels);
                    this.hotel_label = value;
                }else{
                    if($('input[name="hotelSelection_'+ index +'"]:checked').length>0){
                        
                        let filterList = $("input[name='hotelFilter']").val();
                        if(filterList == "" || filterList == "All hotel"){
                            $("input[name='hotelFilter']").val(id);
                        }else{
                            $("input[name='hotelFilter']").val(filterList + ", "+ id);
                        }

                        if(this.hotel_label== "" || this.hotel_label == this.lang.all_hotels){
                            this.hotel_label = value;
                        }else{
                            this.hotel_label = this.hotel_label + ", "+ value;
                        }
                                               
                    }else{
                        let currentVal = "";
                        let filterList = $("input[name='hotelFilter']").val();
                        if(filterList.includes(id)){
                            currentVal = filterList.replace(id, "");
                            currentVal = this.formatCommaString(currentVal);

                            $("input[name='hotelFilter']").val(currentVal.trim());
                        }

                        if(this.hotel_label.includes(value)) {
                            this.hotel_label = this.hotel_label.replace(value, "");
                            this.hotel_label = this.formatCommaString(this.hotel_label);
                        }
                    }
                }

                if(this.hotel_label == ""){ $("input[name='allHotelSelection']").trigger("click"); }
            },
            setFilterByArea(id, value, index) { 
                if(value==this.lang.all_areas){ 
                    for(let i=0; i<this.area_list.length; i++){
                        $('input[name="areaSelection_'+ i +'"]').prop('checked', false);
                    }

                }else{ 
                    $('input[name="allAreaSelection"]').prop('checked', false);
                } 
                
                if(value==this.lang.all_areas){
                    $("input[name='areaFilter']").val(this.lang.all_areas);
                    this.area_label = value;
                }else{
                    if($('input[name="areaSelection_'+ index +'"]:checked').length>0){
                        
                        let filterList = $("input[name='areaFilter']").val();
                        if(filterList == "" || filterList == "All areas"){
                            $("input[name='areaFilter']").val(id);
                        }else{
                            $("input[name='areaFilter']").val(filterList + ", "+ id);
                        }

                        if(this.area_label== "" || this.area_label == this.lang.all_areas){
                            this.area_label = value;
                        }else{
                            this.area_label = this.area_label + ", "+ value;
                        }
                                               
                    }else{
                        let currentVal = "";
                        let filterList = $("input[name='areaFilter']").val();
                        if(filterList.includes(id)){
                            currentVal = filterList.replace(id, "");
                            currentVal = this.formatCommaString(currentVal);

                            $("input[name='areaFilter']").val(currentVal.trim());
                        }

                        if(this.area_label.includes(value)) {
                            this.area_label = this.area_label.replace(value, "");
                            this.area_label = this.formatCommaString(this.area_label);
                        }
                    }
                }

                if(this.area_label == ""){ $("input[name='allAreaSelection']").trigger("click"); }
            },
            setFilterByGuest(id, value, index) {
                if(value==this.lang.all_guests){ 
                    for(let i=0; i<this.list_guests.length; i++){
                        $('input[name="guestSelection_'+ i +'"]').prop('checked', false);
                    }

                }else{ 
                    $('input[name="allGuestSelection"]').prop('checked', false);
                } 
                
                if(value==this.lang.all_guests){
                    $("input[name='guestFilter']").val(this.lang.all_guests);
                    this.guest_label = value;
                }else{
                    if($('input[name="guestSelection_'+ index +'"]:checked').length>0){
                        
                        let filterList = $("input[name='guestFilter']").val();
                        if(filterList == "" || filterList == "All guests"){
                            $("input[name='guestFilter']").val(id);
                        }else{
                            $("input[name='guestFilter']").val(filterList + ", "+ id);
                        }

                        if(this.guest_label== "" || this.guest_label == this.lang.all_guests){
                            this.guest_label = value;
                        }else{
                            this.guest_label = this.guest_label + ", "+ value;
                        }
                                               
                    }else{
                        let currentVal = "";
                        let filterList = $("input[name='guestFilter']").val();
                        if(filterList.includes(id)){
                            currentVal = filterList.replace(id, "");
                            currentVal = this.formatCommaString(currentVal);

                            $("input[name='guestFilter']").val(currentVal.trim());
                        }

                        if(this.guest_label.includes(value)) {
                            this.guest_label = this.guest_label.replace(value, "");
                            this.guest_label = this.formatCommaString(this.guest_label);
                        }
                    }
                }

                if(this.guest_label == ""){ $("input[name='allGuestSelection']").trigger("click"); }
            },
            setFilterBySrv(id, value, index) {
                if(value==this.lang.all_srv){ 
                    for(let i=0; i<this.list_services.length; i++){
                        $('input[name="srvSelection_'+ i +'"]').prop('checked', false);
                    }

                }else{ 
                    $('input[name="allSrvSelection"]').prop('checked', false);
                } 
                
                if(value==this.lang.all_srv){
                    $("input[name='srvFilter']").val(this.lang.all_srv);
                    this.srv_label = value;
                }else{
                    if($('input[name="srvSelection_'+ index +'"]:checked').length>0){
                        
                        let filterList = $("input[name='srvFilter']").val();
                        if(filterList == "" || filterList == "All services"){
                            $("input[name='srvFilter']").val(id);
                        }else{
                            $("input[name='srvFilter']").val(filterList + ", "+ id);
                        }

                        if(this.srv_label== "" || this.srv_label == this.lang.all_srv){
                            this.srv_label = value;
                        }else{
                            this.srv_label = this.srv_label + ", "+ value;
                        }
                                               
                    }else{
                        let currentVal = "";
                        let filterList = $("input[name='srvFilter']").val();
                        if(filterList.includes(id)){
                            currentVal = filterList.replace(id, "");
                            currentVal = this.formatCommaString(currentVal);

                            $("input[name='srvFilter']").val(currentVal.trim());
                        }

                        if(this.srv_label.includes(value)) {
                            this.srv_label = this.srv_label.replace(value, "");
                            this.srv_label = this.formatCommaString(this.srv_label);
                        }
                    }
                }

                if(this.srv_label == ""){ $("input[name='allSrvSelection']").trigger("click"); }
            },
            setFilterByWallet(id, value, index) {
                if(value==this.lang.all_wallets){ 
                    for(let i=0; i<this.wallet_list.length; i++){
                        $('input[name="walletSelection_'+ i +'"]').prop('checked', false);
                    }

                }else{ 
                    $('input[name="allWalletSelection"]').prop('checked', false);
                } 
                
                if(value==this.lang.all_wallets){
                    $("input[name='walletFilter']").val(this.lang.all_wallets);
                    this.wallet_label = value;
                }else{
                    if($('input[name="walletSelection_'+ index +'"]:checked').length>0){
                        
                        let filterList = $("input[name='walletFilter']").val();
                        if(filterList == "" || filterList == "All wallets"){
                            $("input[name='walletFilter']").val(id);
                        }else{
                            $("input[name='walletFilter']").val(filterList + ", "+ id);
                        }

                        if(this.wallet_label== "" || this.wallet_label == this.lang.all_wallets){
                            this.wallet_label = value;
                        }else{
                            this.wallet_label = this.wallet_label + ", "+ value;
                        }
                                               
                    }else{
                        let currentVal = "";
                        let filterList = $("input[name='walletFilter']").val();
                        if(filterList.includes(id)){
                            currentVal = filterList.replace(id, "");
                            currentVal = this.formatCommaString(currentVal);

                            $("input[name='walletFilter']").val(currentVal.trim());
                        }

                        if(this.wallet_label.includes(value)) {
                            this.wallet_label = this.wallet_label.replace(value, "");
                            this.wallet_label = this.formatCommaString(this.wallet_label);
                        }
                    }
                }

                if(this.wallet_label == ""){ $("input[name='allWalletSelection']").trigger("click"); }
            },
            setFilterByOperation(id, value, index) {
                if(value==this.lang.all_operations){ 
                    for(let i=0; i<this.operations_list.length; i++){
                        $('input[name="operationSelection_'+ i +'"]').prop('checked', false);
                    }

                }else{ 
                    $('input[name="allOperationSelection"]').prop('checked', false);
                } 
                
                if(value==this.lang.all_operations){
                    $("input[name='operationFilter']").val(this.lang.all_operations);
                    this.operation_label = value;
                }else{
                    if($('input[name="operationSelection_'+ index +'"]:checked').length>0){
                        
                        let filterList = $("input[name='operationFilter']").val();
                        if(filterList == "" || filterList == "All operations"){
                            $("input[name='operationFilter']").val(id);
                        }else{
                            $("input[name='operationFilter']").val(filterList + ", "+ id);
                        }

                        if(this.operation_label== "" || this.operation_label == this.lang.all_operations){
                            this.operation_label = value;
                        }else{
                            this.operation_label = this.operation_label + ", "+ value;
                        }
                                               
                    }else{
                        let currentVal = "";
                        let filterList = $("input[name='operationFilter']").val();
                        if(filterList.includes(id)){
                            currentVal = filterList.replace(id, "");
                            currentVal = this.formatCommaString(currentVal);

                            $("input[name='operationFilter']").val(currentVal.trim());
                        }

                        if(this.operation_label.includes(value)) {
                            this.operation_label = this.operation_label.replace(value, "");
                            this.operation_label = this.formatCommaString(this.operation_label);
                        }
                    }
                }

                if(this.operation_label == ""){ $("input[name='allOperationSelection']").trigger("click"); }
            },
            formatCommaString (str) {
                let out = "";
                let s = str.split(", ");
                for(let i=0; i<s.length; i++){ 
                    if(s[i].length == 0 || s[i].includes(",")) { continue; }

                    if(out==""){
                        out = s[i];
                    }else{ 
                        out += ", "+s[i];
                    }
                }

                return out;
            },
        }
    }
</script>

<style>
    .modal-fade-enter, .modal-fade-enter-active,
    .modal-fade-leave-active {
        opacity: 0;
    }
    .modal-fade-enter-to, .modal-fade-leave {
        opacity: 1;
    }

    .modal-fade-enter-active,
    .modal-fade-leave-active {
        transition: opacity .5s ease
    }
    .modal-backdrop {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0.3);
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .modal {
        background: #FFFFFF;
        box-shadow: 2px 2px 20px 1px;
        overflow-x: auto;
        display: flex;
        flex-direction: column;
    }
    .modal-header,
    .modal-footer {
        padding: 15px;
        display: flex;
    }
    .modal-header {
        border-bottom: 1px solid #eeeeee;
        color: #4AAE9B;
        justify-content: space-between;
    }
    .modal-header .btn-close {
        padding: 0px;
        margin-top: 5px;
        margin-right: 15px;
    }
    .modal-header, .modal-footer {
        padding: 15px;
        display: flex;
    }
    span.content-header {
        line-height: 30px;
        color: #2EB085;
        font-size: 1.4rem;
        cursor: default;
        margin: 5px;
    }
    .modal-footer {
        border-top: 1px solid #eeeeee;
        justify-content: flex-end;
    }
    .modal-body {
        position: relative;
        padding: 20px 10px;
        overflow: auto;
    }
    .btn-close {
        border: none;
        font-size: 20px;
        padding: 20px;
        cursor: pointer;
        font-weight: bold;
        color: #4AAE9B;
        background: transparent;
    }
    .btn-green {
        color: white;
        background: #4AAE9B;
        border: 1px solid #4AAE9B;
        border-radius: 2px;
    }
    #filter-modal-card {
        height: auto; 
        max-height: 95%;
        position: unset;
    }
    #filter-modal-card.blocks, #filter-modal-card.report {
        width: 800px;
        min-height: 400px;
    }
    #filter-modal-card.booking, #filter-modal-card.rsv_services, #filter-modal-card.transactions {
        width: 800px;
        min-height: 550px;
    }
    #filter-modal-card.customers, #filter-modal-card.daily_services {
        width: 400px;
    }
    #filter-modal-card.daily_services, #filter-modal-card.daily_services section.modal-body, #filter-modal-card.transactions, #filter-modal-card.transactions section.modal-body {
        overflow: visible;
    }
    div#filter-modal-card.customers .div-contain-filter, #filter-modal-card.daily_services .div-contain-filter {
        margin-bottom: -10px;
    }
    #filter-modal-card.customers input, #filter-modal-card.daily_services input, #filter-modal-card.daily_services #srv-list {
        margin-bottom: 20px;
    }
    .titleFilter {
        color: #6c757d !important;
        margin-top: 0px !important;
        margin-left: 50px;
        font-size: .875rem;
    }
    .filter-input {
        max-width: 300px;
        min-height: unset;
        height: 40px;
        font-size: 0.775rem;        
    }
    input.fake-field {
        visibility: hidden;
    }
    .div-contain-filter {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        margin-bottom: 20px;
        justify-content: space-around;
    }
    .div-contain-filter.single-element {
        justify-content: flex-start;
    }
    #hotel-list, #area-list, #guest-list, #srv-list, #wallet-list, #operation-list {
        min-width: 300px;
        max-width: 300px;
        width: 300px;
    }
    #hotel-list button, #area-list button, #guest-list button, #srv-list button, #wallet-list button, #operation-list button  {
        min-height: unset;
        font-size: .875rem;
        height: 40px !important;
    }
    #hotel-list button, #area-list button, #guest-list button, #srv-list button, #wallet-list button, #operation-list button {
        cursor: pointer;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        padding-right: 40px;
    }
    .dropdown-item.not-close-onclick {
        padding-top: 10px;
        padding-bottom: 0px;
    }
    .content-filter {
        position: relative;
        top: -15px;
        left: 10px;
    }
    .center-txt {
        text-align: center;
    }
    .close-item {
        cursor: pointer;
        padding-top: 0px !important;
    }
    /*.div-contain-filter.contain-date input {
        margin-right: 20px;
        max-width: 200px;
    }
    .div-contain-filter.contain-date {
        width: 300px;
        color: #6c757d;
        align-items: baseline;
        justify-content: flex-start;
    }*/
    .div-contain-filter i {
        color: #778899;
        font-size: 1.3rem;
        margin-left: -53px;
        margin-right: -53px;
    }
    #guest-option {
        max-height: 250px !important;
    }
    .flag-div {
        margin-left: 50px;
        color: #6c757d !important;
    }
    div#operation-option {
        max-height: 290px !important;
    }

    @media screen and (max-width: 850px) {
        #filter-modal-card.booking, #filter-modal-card.rsv_services, #filter-modal-card.transactions {
            width: 90% !important;
        }
    }

    @media screen and (min-width: 781px) and (max-width: 850px) {
        .titleFilter {
            margin-left: 30px;
        }
        .div-contain-filter {
            padding-left: 30px;
            padding-right: 30px;
            justify-content: space-between;
        }
        /*.div-contain-filter.contain-date {
            padding-left: 0px;
            padding-right: 0px;
            justify-content: flex-start;
        }*/
    }

    @media screen and (min-width: 781px) {
        .div-contain-filter i.arrow-mobile {
            display: none;
        }
    }

    @media screen and (max-width: 780px) {
        .titleFilter {
            margin-left: 30px;
        }
        .flag-div {
            margin-left: 30px;
        }
        .filter-input, #hotel-list, #area-list, #guest-list, #srv-list, #wallet-list, #operation-list {
            width: calc(100% - 60px);
            margin-bottom: 15px;
            min-width: unset;
            max-width: unset;
        }
        /*.div-contain-filter.contain-date {
            width: 100%;
            padding-left: 30px;
            padding-right: 30px;
        }*/
        .div-contain-filter i.arrow-dsk {
            display: none !important;
        }
        .div-contain-filter i.arrow-mobile {
            display: block !important;
        }
        .div-contain-filter i {
            margin-left: 0px;
            margin-right: 0px;
        }
        .div-contain-filter.date {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
    }

    @media screen and (max-width: 500px) {
        span.content-header {
            font-size: 1.18rem;
        }
        /*.div-contain-filter.contain-date {
            padding-left: 20px;
            padding-right: 15px;
        }
        .div-contain-filter.contain-date input {
            margin-right: 10px;
            margin-left: 10px;
            max-width: 170px;
        }*/
    }

    /*@media screen and (max-width: 350px) {
        .div-contain-filter.contain-date input {
            max-width: 140px;
        }
    }*/

    @media screen and (max-width: 450px) {
        .div-contain-filter.date input {
            padding-top: 0.375rem !important;
        }
        #filter-modal-card.customers, #filter-modal-card.daily_services {
            width: 90% !important;
        }
    }
</style>