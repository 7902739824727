<template>
    <transition name="modal-fade">
        <div class="modal-backdrop">
            <div id="card_list_modal" class="modal opacity-class" role="dialog" aria-labelledby="modalTitle" aria-describedby="modalDescription">
                <header class="modal-header">
                    <slot name="header">
                        <span class="content-header">{{ title_name }}</span> <!-- {{ lang.card_list }}: -->

                        <button type="button" class="btn-close" @click="close" aria-label="Close modal">x</button>
                    </slot>
                </header>

                <section class="modal-body">
                    <slot name="body"> 
                        <p class='alert' v-if="cards.length == 0">{{ lang.no_card_guest }}</p>

                        <table v-if="cards.length > 0" id="card-list" class="table table-striped ic-table">
                            <thead>
                                <tr>
                                    <th>{{ lang.state }}</th>
                                    <th><span v-html="lang.card_numb"/></th>
                                    <th>{{ lang.actions }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <template v-for="(card,i) in cards" :key="i">
                                    <tr>
                                        <td class="dsk_cell">
                                            <template v-if="card.active">
                                                <div class="led-availability greenLight" :title="lang.active_card"></div>
                                            </template>
                                            <template v-else>
                                                <div class="led-availability redLight" :title="lang.deactive_card"></div>
                                            </template>
                                        </td>
                                        <td class="dsk_cell">{{ card.code }}</td>
                                        <td>
                                            <template v-if="card.active">
                                                <button type="button" class="btn action-btn edit" @click="$parent.enableDisableCard(card, false)" :title="lang.block_card">
                                                    <i class="ri-lock-2-line"></i>
                                                </button>
                                            </template>
                                            <template v-else>
                                                <button type="button" class="btn action-btn edit" @click="$parent.enableDisableCard(card, true)" :title="lang.enable_card">
                                                    <i class="ri-lock-unlock-line"></i>
                                                </button>
                                            </template>

                                            <button type="button" class="btn action-btn" @click="$parent.delGuestCard(card.id, card.code)" :title="lang.delete_card"> 
                                                <i class="ri-delete-bin-line"></i>
                                            </button>
                                        </td>
                                    </tr>
                                </template>
                            </tbody>
                        </table>

                        <Loader v-if="isLoading" idVal="" classVal="loader-card-info" />
                    </slot>
                </section>

                <footer class="modal-footer">
                    <slot name="footer">
                        <button type="button" id="close-alert" class="btn ic-btn-small ic-btn-green" @click="close" aria-label="Close modal">{{ lang.close }}</button>
                    </slot>
                </footer> 
            </div>
        </div>
    </transition>
</template>

<script>
    import * as Utility from '../../js/utility.js'
    import { useGettext } from '@jshmrtn/vue3-gettext'

    import Loader from '../elements/Loader.vue'

    export default {
        name: "CardListModal",
        components: {
            Loader
        },  
        props: {
            cards: [],
            title_name: String,
        },
        data() {
            return {
                lang: "",
                isLoading: false,
            }
        },
        setup() { 
            const language = useGettext();
            return {
               language,
            };
        },
        mounted() {  
            this.lang = Utility.getLanguage(this.language);
        },
        methods: {
            close() {
                this.$emit('close');
            }
        }
    }
</script>

<style>
    /* transition modal */
    .modal-fade-enter, .modal-fade-enter-active,
    .modal-fade-leave-active {
        opacity: 0;
    }
    .modal-fade-enter-to, .modal-fade-leave {
        opacity: 1;
    }

    .modal-fade-enter-active,
    .modal-fade-leave-active {
        transition: opacity .5s ease
    }
    .modal-backdrop {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0.3);
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .modal {
        background: #FFFFFF;
        box-shadow: 2px 2px 20px 1px;
        overflow-x: auto;
        display: flex;
        flex-direction: column;
    }
    .modal-header,
    .modal-footer {
        padding: 15px;
        display: flex;
    }
    .modal-header {
        border-bottom: 1px solid #eeeeee;
        color: #4AAE9B;
        justify-content: space-between;
    }
    .modal-footer {
        border-top: 1px solid #eeeeee;
        justify-content: flex-end;
    }
    .modal-body {
        position: relative;
        padding: 20px 10px;
        overflow: auto;
    }
    .btn-close {
        border: none;
        font-size: 20px;
        padding: 20px;
        cursor: pointer;
        font-weight: bold;
        color: #4AAE9B;
        background: transparent;
    }
    .btn-green {
        color: white;
        background: #4AAE9B;
        border: 1px solid #4AAE9B;
        border-radius: 2px;
    }
    .modal-header .btn-close {
        padding: 0px;
        margin-top: 5px;
        margin-right: 15px;
    }
    .modal-header .btn-close:active{
        position: relative;
        color: #248767;
        top: 1px;
    }
    .modal-header button.btn-close:focus {
        outline: none;
    }
    span.content-header {
        font-size: 23px;
        position: relative;
        top: 5px;
    }

    #card_list_modal {
        width: 500px;
        max-height: 90%; 
        position: unset;        
        transition: width 1s, height 1s;
    }
    #card_list_modal section.modal-body {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    section.modal-body p {
        color: #495057;
        margin-top: 15px;
    }
    .loader-card-info {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        background-color: rgba(255,255,255,.6);
    }
    .ic-table.table-striped thead th, .ic-table.table-striped td, .ic-table.table-striped th {
        vertical-align: middle;
    }
    #card_list_modal section.modal-body p.alert {
        text-align: center;
    }

    @media screen and (max-width: 550px) {
        #card_list_modal {
            width: 90%;
        }
    }
</style>